<template>
  <div>
    <div v-for="(allocationValue, allocationChannelName) in allocationsByChannel"
         :key="allocationChannelName">
      <b-form-group :label="allocationChannelName" label-cols="3">
        <percentage-input :value="allocationsByChannel[allocationChannelName]"
                          @input="updateAllocations($event, allocationChannelName)" />
      </b-form-group>
    </div>
  </div>
</template>

<script>
/*
        final Map<String, Double> allocationMap = new LinkedHashMap<>();
        allocationMap.put( "Search", 1.0 );
        allocationMap.put( "Facebook", 0.4);
        allocationMap.put( "Display", 0.10);
        allocationMap.put( "Waze", 0.15);
        allocationMap.put( "Video", 0.25);
 */
import PercentageInput from 'core-ui/components/inputs/percentageInput'
export default {
  name: 'prospectAllocationsByChannel',
  components: { PercentageInput },
  props: ['value'],
  data () {
    return {
      allocationsByChannel: {
        Search: 0.52,
        Facebook: 0.21,
        Display: 0.05,
        Waze: 0.07,
        Video: 0.13
      }
    }
  },
  watch: {
    value: {
      handler (newValue, oldValue) {
        if (newValue) {
          this.$setCompat(this, 'allocationsByChannel', newValue)
        }
      },
      immediate: true
    }
  },
  methods: {
    updateAllocations (e, channel) {
      this.$setCompat(this.allocationsByChannel, channel, e)

      this.$emit('input', this.allocationsByChannel)
    }
  }
}
</script>

<style scoped>

</style>
