<template>
  <div>
    <div v-for="(sectionVal, sectionKey) in value">
      <b-form-group :label="formLabel(sectionKey)">
        <b-form-textarea :value="sectionVal" rows="5" @input="updateSectionText(sectionKey, $event)"/>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'prospectEditSectionText',
  props: ['value'],
  methods: {
    updateSectionText (key, val) {
      let sectionText = _.cloneDeep(this.value)
      sectionText[key] = val
      this.$emit('input', sectionText)
    },
    formLabel (key) {
      let label = ''
      key.replace('prospect-', '').split('-').forEach(keySegment => {
        label += this.$filters.capitalize(keySegment) + ' '
      })
      return label
    }
  }
}
</script>

<style scoped>

</style>
