<template>
  <div class="row">
    <div class="col">
      <b-form-group label="Markup Percentage">
        <percentage-input :full-width="true" :value="config.markupPercentage || 0" @input="$emit('input', {...config, markupPercentage: $event})"/>
      </b-form-group>
    </div>
    <div class="col">
      <b-form-group label="Markup Flat">
        <b-input-group prepend="$">
          <b-input :value="config.markupFlat || 0" @input="$emit('input', {...config, markupFlat: $event})"></b-input>
        </b-input-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import PercentageInput from 'core-ui/components/inputs/percentageInput'

export default {
  name: 'prospectMarkup',
  components: { PercentageInput },
  props: ['value'],
  data () {
    return {
      markupType: 'markupFlat',
      markupValue: null,
      config: {}
    }
  },
  watch: {
    value: {
      handler (newVal) {
        this.$setCompat(this, 'config', newVal)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
