<template>
  <div>
    <b-form-checkbox-group :checked="selected" @input="selected = $event; emitData()" :options="options" stacked />
  </div>
</template>

<script>
export default {
  name: 'prospectCheckboxMap',
  props: ['value', 'eligibleBlueprints'],
  data () {
    return {
      selected: [],
      options: []
    }
  },
  watch: {
    eligibleBlueprints: {
      handler (newVal) {
        const newArr = []
        if (newVal) {
          Object.keys(newVal).forEach(ky => { newArr.push({ text: newVal[ky], value: parseInt(ky, 10) }) })
        }
        this.$setCompat(this, 'options', newArr)
      },
      immediate: true
    },
    value: {
      handler (newVal, oldVal) {
        const newArr = []
        if (newVal && Object.keys(newVal).length > 0) {
          Object.keys(newVal).forEach(ky => newArr.push(parseInt(ky, 10)))
        }
        this.$setCompat(this, 'selected', newArr)
      },
      immediate: true
    }
  },
  methods: {
    emitData () {
      const mp = {}
      this.selected.forEach(itm => {
        mp[itm] = this.eligibleBlueprints[itm]
      })
      this.$emit('input', mp)
    }
  }
}
</script>

<style scoped>

</style>
