<template>
  <div class="d-flex">
    <b-form-textarea :value="postalCodes" rows="15"
                     @input="postalCodes = $event; updatePostalCodes()"></b-form-textarea>
  </div>
</template>

<script>
export default {
  name: 'prospectTargetedPostalCodesEditor',
  props: ['value'],
  data () {
    return {
      postalCodes: '',
      removedCodes: [],
      addCode: {
        postalCode: ''
      },
      selectedActive: [],
      selectedRemoved: []
    }
  },
  watch: {
    value: {
      handler (newValue, oldValue) {
        const postalCodes = this.value?.map(pc => pc.postalCode).join('\n') ?? ''
        this.$setCompat(this, 'postalCodes', postalCodes)
      },
      immediate: true
    }
  },
  methods: {
    updatePostalCodes () {
      const postalCodes = []
      this.postalCodes.split('\n').forEach(pc => {
        postalCodes.push({ postalCode: pc })
      })
      this.$emit('input', postalCodes)
    }
  }
}
</script>

<style scoped>

</style>
