<template>
  <div>
    <collapsible-card>
      <template #header><span>{{postalCode.name}}</span></template>
      <div class="form-row">
        <t-col>
          <b-form-group label="Lat">
            <b-input v-model="postalCode.lat"/>
          </b-form-group>
        </t-col>
        <t-col>
          <b-form-group label="Long">
            <b-input v-model="postalCode.lon"/>
          </b-form-group>
        </t-col>
      </div>
      <div class="form-row">
        <t-col>
          <b-form-group label="City">
            <b-input v-model="postalCode.city"/>
          </b-form-group>
        </t-col>
        <t-col>
          <b-form-group label="State">
            <b-input v-model="postalCode.state"/>
          </b-form-group>
        </t-col>
        <t-col>
          <b-form-group label="Postal Code">
            <b-input v-model="postalCode.postalCode"/>
          </b-form-group>
        </t-col>
      </div>
    </collapsible-card>
  </div>
</template>

<script>
import CollapsibleCard from '@/components/common/widgets/collapsibleCard'
export default {
  name: 'prospectPostalCodeEditor',
  components: { CollapsibleCard },
  props: ['value'],
  data () {
    return {
      postalCode: {}
    }
  },
  watch: {
    value: {
      handler (newVal) {
        this.$setCompat(this, 'postalCode', newVal)
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
